import React, { useRef } from 'react';

import { Flex, Spinner, Stack } from '@chakra-ui/react';
import { FiPrinter } from 'react-icons/fi';
import { Navigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import './index.css';

import { useFetchData } from '../../contexts/FetchDataProvider';

import Button from '../../components/Button';
import { FreeVehicleHistoryDownloadButton } from '../../components/FreeVehicleHistoryDownloadButton';
import PayerReceiptModal from '../../components/PayerReceiptModal';
import Text from '../../components/Text';
import AtpvDocument from './components/AtpvDocument';
import WhatsAppSupportButton from '../../components/WhatsAppSupportButton';

const Atpv: React.FC = () => {
	const {
		licensePlateData,
		report,
		setReport,
		getInquiries,
		inquiries,
		inquiriesLoading,
		atpveUrl,
		setAtpveUrl,
		isAtpveSuccess,
		atpveUrlLoading,
	} = useFetchData();

	const componentRef = useRef<HTMLDivElement>(null);
	const handlePrint = () => {
		if (componentRef.current) {
			componentRef.current.click();
		}
	};

	React.useEffect(() => {
		getInquiries();
		//eslint-disable-next-line
	}, [report]);

	React.useEffect(() => {
		if (atpveUrl) {
			window.open(atpveUrl, '_blank')
			setAtpveUrl(undefined);
		}
		//eslint-disable-next-line
	}, [atpveUrl]);

	return licensePlateData === undefined ? (
		<Navigate to='/admin/busca' />
	) : (
		<>
			<Stack px={[2, 2, 20]} py={[2, 2, 5]} w='100%'>
				<AtpvDocument
					componentRef={componentRef}
					inquiries={inquiries}
					licensePlateData={licensePlateData}
					report={report}
					isAtpveSuccess={isAtpveSuccess}
					atpveUrlLoading={atpveUrlLoading}
				/>
				<FreeVehicleHistoryDownloadButton report={report} />
				<Stack direction={['column', 'column', 'row']} justifyContent='space-between' alignItems='start'>
					<Flex flexDirection='column'>
						<>
							<Flex justifyContent={['center', 'center', 'start']}>
								<PayerReceiptModal report={report} setReport={setReport} inquiries={inquiries} />
							</Flex>
							{licensePlateData.stateRegistration === 'MG' && (
								<Stack>
									<Flex flexDirection='column' mt='8px'>
										{inquiriesLoading ? (
											<Flex>
												<Stack>
													<Spinner mr='5px' />
												</Stack>
												<Text textAlign={['center', 'center', 'start']} fontWeight='bold'>
													Pesquisando débitos.
												</Text>
											</Flex>
										) : (
											<>
												<Text textAlign={['center', 'center', 'start']} fontWeight='bold'>
													{inquiries?.inconsistent_data
														? 'Cartório, peça a seu cliente que verifique os débitos no link disponibilizado.'
														: inquiries?.debitos?.multas || inquiries?.debitos?.ipva || inquiries?.debitos?.licenciamento
														? 'Este veículo possui débitos.'
														: 'Este veículo não possui débitos.'}
												</Text>
												<Text textAlign={['center', 'center', 'start']} className='red-star'>
													Os débitos referem-se a IPVA, Licenciamento ou Multas.
												</Text>
												<Text textAlign={['center', 'center', 'start']} className='red-star'>
													Multas: Somente serão exibidas as multas disponíveis no Departamento de Trânsito de MG.
												</Text>
											</>
										)}
									</Flex>
								</Stack>
							)}
						</>
					</Flex>
					<Flex flexDirection='column'>
						<Flex justifyContent={['center', 'center', 'end']} gap='10px'>
							<ReactToPrint
								content={() => componentRef.current}
								trigger={() => (
									<Button id='button__print' onClick={handlePrint}>
										<FiPrinter />
									</Button>
								)}
							/>
						</Flex>
						<Stack>
							<Flex flexDirection='row' mt='8px'>
								<Text textAlign={['center', 'center', 'end']} className='red-star'>
									O resultado desta consulta será enviada de forma automática para o telefone do cliente.
									<br />
									O cartório que preferir pode realizar a impressão desta página e anexar ao documento original.
								</Text>
							</Flex>
						</Stack>
					</Flex>
				</Stack>
				<WhatsAppSupportButton />
			</Stack>
		</>
	);
};

export default Atpv;
