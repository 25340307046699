import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/Home';
import { Box, Icon, Stack } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';

import './assets/css/index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FetchDataProvider } from './contexts/FetchDataProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { ToastProps, ToastProvider } from 'react-toast-notifications';
import './index.css'
import Text from './components/Text';
import { RefreshTokenProvider } from './contexts/RefreshTokenProvider';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

const CustomToast = ({ appearance, children }: ToastProps) => (
	<Box
		className={appearance === 'error' ? 'toast__error' : 'toast__success'}
		rounded='xl'
		bg={appearance === 'error' ? '#E85642' : '#07C50E'}
		py={8}
		px={15}
	>
		<Stack isInline spacing={12} alignItems='center'>
			<Icon as={appearance === 'error' ? MdError : FaCheck} boxSize={22} color='white' />
			<Text color='white' fontSize='xs' fontWeight='bold'>
				{children}
			</Text>
		</Stack>
	</Box>
);

const pubNub = new PubNub({
	publishKey: `${process.env.REACT_APP_PUBNUB_PUBLISH_KEY}`,
	subscribeKey: `${process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY}`,
	uuid: `${process.env.REACT_APP_PUBNUB_UUID}`
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(

		<PubNubProvider client={pubNub}>
			<ToastProvider components={{ Toast: CustomToast }}>
				<AuthProvider>
					<RefreshTokenProvider>
						<FetchDataProvider>
							<App />
						</FetchDataProvider>
					</RefreshTokenProvider>
				</AuthProvider>
			</ToastProvider>
		</PubNubProvider>

);
