import React from 'react';
import { useLocation } from 'react-router-dom';

import { GetReportDtoDocumentTypeEnum } from '../../clients';

import AtpvDocument from '../Atpv/components/AtpvDocument';
import CrvDocument from '../Crv/components/CrvDocument';

import { PayerReceipt } from '../../components/PayerReceipt';
import './styles/index.css';
import { buildObject } from './utils/builder';

const DownloadPayerReceipt: React.FC = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const { vehicle, reports } = buildObject(searchParams);

	return (
		<>
			{vehicle && (
				<>
					<div className='print-section'>
						{reports.document_type === GetReportDtoDocumentTypeEnum.Crv ? (
							<CrvDocument licensePlateData={vehicle} report={reports} />
						) : (
							<AtpvDocument licensePlateData={vehicle} report={reports} />
						)}
					</div>
					<div className='print-section'>
						<PayerReceipt report={reports} seller={reports.seller} />
					</div>
				</>
			)}
		</>
	);
};

export default DownloadPayerReceipt;
