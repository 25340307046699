import React, { useRef } from 'react';

import { Box, Checkbox, Flex, Modal, ModalContent, ModalOverlay, Stack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { TbReportMoney } from 'react-icons/tb';
import ReactToPrint from 'react-to-print';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';

import { GetReportDto, GetVehicleInquiriesDto, ReportsApi } from '../clients';

import { DataValues } from '../pages/Reports/interfaces/DataValues';

import { getApiAuthConfig } from '../services/api.service';
import { cpfCnpjMask, cpfCnpjValidator } from '../services/cpfCnpj.service';

import { defaultColors } from '../config/variables';

import Text from './Text';
import Button from './Button';
import FormControl from './Form/FormControl';
import FormErrorMessage from './Form/FormErrorMessage';
import Input from './Input';
import { PayerReceipt } from './PayerReceipt';

type PayerReceiptModalProps = {
	report: GetReportDto | undefined;
	sizeButton?: string;
	bgColorButton?: string;
	colorButton?: string;
	variantButton?: string;
	paddingButton?: number | string;
	fetchData?: (dataValues: DataValues) => Promise<void>;
	dataValues?: DataValues;
	setReport?: React.Dispatch<React.SetStateAction<GetReportDto | undefined>>;
	inquiries?: GetVehicleInquiriesDto | undefined;
};

export const PayerReceiptModal: React.FC<PayerReceiptModalProps> = (props: PayerReceiptModalProps) => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const reportsApi = new ReportsApi(apiConfig);
	
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);

	const initialValues = {
		payerFields: !!(props.report?.payer?.name && props.report?.payer?.document),
		payerName: props.report?.payer?.name || '',
		payerDocument: props.report?.payer?.document ? cpfCnpjMask(props.report?.payer?.document) : '',
	};

	const cpfCnpjSchema = yup
		.string()
		.transform((value) => (value ? value.replace(/[^\d]+/g, '') : ''))
		.test('is-valid-cpf-cnpj', 'Documento inválido', function (value) {
			if (value) {
				return cpfCnpjValidator(value as string);
			}
			return true;
		});

	const schema = yup.object().shape({
		payerFields: yup.boolean(),
		payerName: yup.string().when('payerFields', { is: true, then: (schema) => schema.required('Campo Obrigatório') }),
		payerDocument: cpfCnpjSchema.when('payerFields', { is: true, then: (schema) => schema.required('Campo Obrigatório') }),
	});

	const toggleModal = () => {
		setModalOpen((prevState) => !prevState);
	};

	const componentRef = useRef<HTMLDivElement>(null);
	const handlePrint = () => {
		if (componentRef.current) {
			componentRef.current.click();
		}
	};

	const handleFormSubmit = async (values: any) => {
		setIsLoading(true);
		try {
			if (props.report?.id && values.payerName !== '' && values.payerDocument !== '') {
				await reportsApi.addPayerToReport({
					reportId: props.report.id,
					addPayerDto: { payer_name: values.payerName, payer_document: values.payerDocument },
				});
				if (props.fetchData && props.dataValues) {
					await props.fetchData(props.dataValues);
				}
				if (props.setReport) {
					const payer = { name: values.payerName, document: values.payerDocument };
					props.setReport({ ...props.report, payer });
				}
			}
			handlePrint();
		} catch (error) {
			addToast(`Erro ao salvar dados do pagador`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setModalOpen(false);
			setIsLoading(false);
		}
	};

	

	return (
		<>
			<Button
				size={props.sizeButton || 'lg'}
				variant={props.variantButton || 'solid'}
				bgColor={props.bgColorButton || defaultColors.primaryColor}
				color={props.colorButton || '#fff'}
				padding={props.paddingButton}
				alignItems='center'
				onClick={toggleModal}
				gap='5px'
			>
				<TbReportMoney size={22} /> 
				<Text>
					Recibo
				</Text>
			</Button>
			<Modal isOpen={modalOpen} onClose={toggleModal} size='3xl'>
				<ModalOverlay />
				<ModalContent p='15px'>
					<Stack alignItems='center'>
						<Text fontSize={30} color={defaultColors.primaryColor}>
							Imprimir Recibo
						</Text>
						<Box borderRadius='md' w='100%'>
							<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
								{({ handleSubmit, isValid, setFieldValue, values, errors }) => {
									return (
										<form onSubmit={handleSubmit}>
											<Flex p='32px' flexDirection='column'>
												<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
													<Checkbox
														id='input__checkbox-payer-fields'
														name='payerFields'
														color='#757575'
														size='lg'
														isChecked={values.payerFields}
														onChange={(e) => {
															setFieldValue('payerFields', e.target.checked);
														}}
													>
														Incluir dados do pagador
													</Checkbox>
												</FormControl>
												<Flex display={values.payerFields ? 'block' : 'none'}>
													<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
														<Flex display='flex' flexDirection='column' w='100%'>
															<Input
																name='payerName'
																id='input__payer-name'
																placeholder='Nome do Pagador'
																isReadOnly={false}
																border={`1px solid ${defaultColors.lightGreen}`}
															/>
														</Flex>
														<Flex w='100%' justifyContent='flex-end'>
															<Flex w='67%' justifyContent='flex-start'>
																<FormErrorMessage name='payerName' />
															</Flex>
														</Flex>
													</FormControl>
													<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
														<Flex display='flex' flexDirection='column' w='100%'>
															<Input
																name='payerDocument'
																id='input__payer-document'
																placeholder='Documento do Pagador'
																isReadOnly={false}
																border={`1px solid ${defaultColors.lightGreen}`}
																maxLength={18}
																onChange={(e) => setFieldValue('payerDocument', cpfCnpjMask(e.target.value))}
															/>
														</Flex>
														<Flex w='100%' justifyContent='flex-end'>
															<Flex w='67%' justifyContent='flex-start'>
																<FormErrorMessage name='payerDocument' />
															</Flex>
														</Flex>
													</FormControl>
												</Flex>
												<Flex flexDirection='row' alignItems='center' justifyContent='center' fontSize='md' px='12px' pb='8px'>
													<ReactToPrint
														content={() => componentRef.current}
														trigger={() => (
															<Button id='submit_modal-button' isLoading={isLoading} isDisabled={!isValid} size='lg' type='submit'>
																Confirmar
															</Button>
														)}
													/>
												</Flex>

												<Stack p='10px' alignItems='center' ref={componentRef}>
													<PayerReceipt
														report={props.report}
														payerName={values.payerFields ? values.payerName : ''}
														payerDocument={values.payerFields ? values.payerDocument : ''}
														inquiries={props.inquiries}
													/>
												</Stack>
											</Flex>
										</form>
									);
								}}
							</Formik>
						</Box>
					</Stack>
				</ModalContent>
			</Modal>
		</>
	);
};

export default PayerReceiptModal;
