import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { usePubNub } from 'pubnub-react';
import { useToasts } from 'react-toast-notifications';

import PrivateRoute from '../../components/ProtectedRoute';

import Footer from '../../containers/Footer';

import checkIfIsInMaintenance from '../../services/maintenance.service';
import { RealTimeChannel, RealTimeEvent } from '../../services/real-time.service';

import Admin from '../Admin';
import Atpv from '../Atpv';
import Consult from '../Consult';
import Crv from '../Crv';
import ErrorPage from '../ErrorPage';
import Login from '../Login';
import Register from '../Register';
import Reports from '../Reports';
import ResetPassword from '../ResetPassword';
import Search from '../Search';
import Summary from '../Summary';
import Welcome from '../Welcome';
import Maintenance from '../Maintenance';
import Loader from '../../containers/Loader';
import DownloadPayerReceipt from '../DownloadPayerReceipt';
import { useAuth } from '../../contexts/AuthProvider';

function App() {
	const router = createBrowserRouter([
		{
			path: '/',
			element: <Login />,
			errorElement: <ErrorPage />,
		},
		{
			path: '/cadastro',
			element: <Register />,
			errorElement: <ErrorPage />,
		},
		{
			path: '/consultar-debitos/:token',
			element: <Consult />,
			errorElement: <ErrorPage />,
		},
		{
			path: '/download-recibo/:transaction_number',
			element: <DownloadPayerReceipt />,
			errorElement: <ErrorPage />,
		},
		{
			path: '/admin',
			element: (
				<PrivateRoute>
					<Admin />
				</PrivateRoute>
			),
			errorElement: <ErrorPage />,
			children: [
				{
					path: '/admin',
					element: <Welcome />,
					errorElement: <ErrorPage />,
				},
				{
					path: '/admin/resumo',
					element: <Summary />,
					errorElement: <ErrorPage />,
				},
				{
					path: '/admin/busca',
					element: <Search />,
					errorElement: <ErrorPage />,
				},
				{
					path: '/admin/resultado-atpv',
					element: <Atpv />,
					errorElement: <ErrorPage />,
				},
				{
					path: '/admin/resultado-crv',
					element: <Crv />,
					errorElement: <ErrorPage />,
				},
				{
					path: '/admin/historico',
					element: <Reports />,
					errorElement: <ErrorPage />,
				},
			],
		},
		{
			path: '/reset-password',
			element: <ResetPassword />,
			errorElement: <ErrorPage />,
		},
	]);

	const pubNub = usePubNub();
	const { logout } = useAuth();
	const { addToast, removeAllToasts } = useToasts();
	const [isLoading, setLoading] = React.useState(false);
	const [isInMaintenanceMode, setMaintenanceMode] = React.useState(false);

	const subscribeToMaintenanceModeEvent = () => {
		pubNub.addListener({
			message: (event) => {
				const { message, channel } = event;

				if (channel === RealTimeChannel.MAINTENANCE_MODE) {
					const event = message[RealTimeEvent.MAINTENANCE_STATE];
					if (event) {
						const newMaintenanceState = event.all_services?.status === 'down';
						setMaintenanceMode(newMaintenanceState);
					}
				}
			},
		});

		pubNub.subscribe({ channels: [RealTimeChannel.MAINTENANCE_MODE] });
	};

	const checkMaintenanceMode = async () => {
		try {
			setLoading(true);
			const isInMaintenanceResponse = await checkIfIsInMaintenance();

			if (isInMaintenanceResponse) {
				setMaintenanceMode(true);
				setLoading(false);
				return;
			}
		} catch (error) {
			console.error('App - checkMaintenanceMode - Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		subscribeToMaintenanceModeEvent();
		checkMaintenanceMode();

		const { fetch: originalFetch } = window;
		window.fetch = async (...args) => {
			let [resource, config] = args;

			const response = await originalFetch(resource, config);

			if (response.status === 401) {
				removeAllToasts();

				addToast('Sessão expirada, realize o login novamente', {
					appearance: 'error',
					autoDismiss: true,
				});

				logout();
			}

			return response;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main className='App-main'>
			<ChakraProvider>
				{isInMaintenanceMode ? (
					<Maintenance />
				) : (
					<>
						{isLoading ? (
							<Loader isOpen={isLoading} />
						) : (
							<>
								<RouterProvider router={router} />
								<Footer />
							</>
						)}
					</>
				)}
			</ChakraProvider>
		</main>
	);
}

export default App;
