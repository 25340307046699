/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetSellerDto,
} from '../models/index';
import {
    GetSellerDtoFromJSON,
    GetSellerDtoToJSON,
} from '../models/index';

/**
 * 
 */
export class SellersApi extends runtime.BaseAPI {

    /**
     * Buscar cartórios
     */
    async getSellersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSellerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSellerDtoFromJSON));
    }

    /**
     * Buscar cartórios
     */
    async getSellers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSellerDto>> {
        const response = await this.getSellersRaw(initOverrides);
        return await response.value();
    }

}
