/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DownloadPayerReceiptDto,
  GetDataDto,
  GetSameDayReportDto,
} from '../models/index';
import {
    DownloadPayerReceiptDtoFromJSON,
    DownloadPayerReceiptDtoToJSON,
    GetDataDtoFromJSON,
    GetDataDtoToJSON,
    GetSameDayReportDtoFromJSON,
    GetSameDayReportDtoToJSON,
} from '../models/index';

export interface DownloadPayerReceiptRequest {
    downloadPayerReceiptDto: DownloadPayerReceiptDto;
}

export interface GetAtpveDocumentRequest {
    reportId: string;
}

export interface GetInquiriesRequest {
    reportId: string;
    licensePlate: string;
    cns: string;
    reference: string;
    type: GetInquiriesTypeEnum;
}

export interface GetSameDayReportRequest {
    licensePlate: string;
    documentType: GetSameDayReportDocumentTypeEnum;
}

export interface GetVehicleByLicensePlateRequest {
    licensePlate: string;
    documentType: GetVehicleByLicensePlateDocumentTypeEnum;
    whatsapp: string;
    email?: string;
}

export interface GetVehicleHistoryPDFRequest {
    token: string;
    type: GetVehicleHistoryPDFTypeEnum;
}

export interface GetVehicleHistoryPDFV2Request {
    reportId: string;
    type: GetVehicleHistoryPDFV2TypeEnum;
}

/**
 * 
 */
export class VehiclesApi extends runtime.BaseAPI {

    /**
     * Gerar PDF de recibo de pagamento para download
     */
    async downloadPayerReceiptRaw(requestParameters: DownloadPayerReceiptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.downloadPayerReceiptDto === null || requestParameters.downloadPayerReceiptDto === undefined) {
            throw new runtime.RequiredError('downloadPayerReceiptDto','Required parameter requestParameters.downloadPayerReceiptDto was null or undefined when calling downloadPayerReceipt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/vehicles/download-receipt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadPayerReceiptDtoToJSON(requestParameters.downloadPayerReceiptDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Gerar PDF de recibo de pagamento para download
     */
    async downloadPayerReceipt(requestParameters: DownloadPayerReceiptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.downloadPayerReceiptRaw(requestParameters, initOverrides);
    }

    /**
     * Busca documento do ATPV-e
     */
    async getAtpveDocumentRaw(requestParameters: GetAtpveDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getAtpveDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/vehicles/atpve-document/{report_id}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Busca documento do ATPV-e
     */
    async getAtpveDocument(requestParameters: GetAtpveDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getAtpveDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Buscar débitos do veículo
     */
    async getInquiriesRaw(requestParameters: GetInquiriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getInquiries.');
        }

        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getInquiries.');
        }

        if (requestParameters.cns === null || requestParameters.cns === undefined) {
            throw new runtime.RequiredError('cns','Required parameter requestParameters.cns was null or undefined when calling getInquiries.');
        }

        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getInquiries.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getInquiries.');
        }

        const queryParameters: any = {};

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['licensePlate'] = requestParameters.licensePlate;
        }

        if (requestParameters.cns !== undefined) {
            queryParameters['cns'] = requestParameters.cns;
        }

        if (requestParameters.reference !== undefined) {
            queryParameters['reference'] = requestParameters.reference;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/vehicles/inquiries/{report_id}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Buscar débitos do veículo
     */
    async getInquiries(requestParameters: GetInquiriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getInquiriesRaw(requestParameters, initOverrides);
    }

    /**
     * Busca pesquisas realizadas no mesmo dia
     */
    async getSameDayReportRaw(requestParameters: GetSameDayReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSameDayReportDto>> {
        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getSameDayReport.');
        }

        if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
            throw new runtime.RequiredError('documentType','Required parameter requestParameters.documentType was null or undefined when calling getSameDayReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/vehicles/same-day/{licensePlate}`.replace(`{${"licensePlate"}}`, encodeURIComponent(String(requestParameters.licensePlate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSameDayReportDtoFromJSON(jsonValue));
    }

    /**
     * Busca pesquisas realizadas no mesmo dia
     */
    async getSameDayReport(requestParameters: GetSameDayReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSameDayReportDto> {
        const response = await this.getSameDayReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar dados do veículo
     */
    async getVehicleByLicensePlateRaw(requestParameters: GetVehicleByLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDataDto>> {
        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getVehicleByLicensePlate.');
        }

        if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
            throw new runtime.RequiredError('documentType','Required parameter requestParameters.documentType was null or undefined when calling getVehicleByLicensePlate.');
        }

        if (requestParameters.whatsapp === null || requestParameters.whatsapp === undefined) {
            throw new runtime.RequiredError('whatsapp','Required parameter requestParameters.whatsapp was null or undefined when calling getVehicleByLicensePlate.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        if (requestParameters.whatsapp !== undefined) {
            queryParameters['whatsapp'] = requestParameters.whatsapp;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/vehicles/{licensePlate}`.replace(`{${"licensePlate"}}`, encodeURIComponent(String(requestParameters.licensePlate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDataDtoFromJSON(jsonValue));
    }

    /**
     * Buscar dados do veículo
     */
    async getVehicleByLicensePlate(requestParameters: GetVehicleByLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDataDto> {
        const response = await this.getVehicleByLicensePlateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar histórico do veículo
     */
    async getVehicleHistoryPDFRaw(requestParameters: GetVehicleHistoryPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getVehicleHistoryPDF.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getVehicleHistoryPDF.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/vehicles/history/{token}/{type}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Buscar histórico do veículo
     */
    async getVehicleHistoryPDF(requestParameters: GetVehicleHistoryPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getVehicleHistoryPDFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar histórico do veículo
     */
    async getVehicleHistoryPDFV2Raw(requestParameters: GetVehicleHistoryPDFV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getVehicleHistoryPDFV2.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getVehicleHistoryPDFV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/vehicles/history/{reportId}/{type}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Buscar histórico do veículo
     */
    async getVehicleHistoryPDFV2(requestParameters: GetVehicleHistoryPDFV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getVehicleHistoryPDFV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetInquiriesTypeEnum = {
    Crv: 'CRV',
    Atpve: 'ATPVe'
} as const;
export type GetInquiriesTypeEnum = typeof GetInquiriesTypeEnum[keyof typeof GetInquiriesTypeEnum];
/**
 * @export
 */
export const GetSameDayReportDocumentTypeEnum = {
    Crv: 'CRV',
    Atpve: 'ATPVe'
} as const;
export type GetSameDayReportDocumentTypeEnum = typeof GetSameDayReportDocumentTypeEnum[keyof typeof GetSameDayReportDocumentTypeEnum];
/**
 * @export
 */
export const GetVehicleByLicensePlateDocumentTypeEnum = {
    Crv: 'CRV',
    Atpve: 'ATPVe'
} as const;
export type GetVehicleByLicensePlateDocumentTypeEnum = typeof GetVehicleByLicensePlateDocumentTypeEnum[keyof typeof GetVehicleByLicensePlateDocumentTypeEnum];
/**
 * @export
 */
export const GetVehicleHistoryPDFTypeEnum = {
    Pexhistoricototalplus: 'PEXHISTORICOTOTALPLUS',
    Pexbasica: 'PEXBASICA'
} as const;
export type GetVehicleHistoryPDFTypeEnum = typeof GetVehicleHistoryPDFTypeEnum[keyof typeof GetVehicleHistoryPDFTypeEnum];
/**
 * @export
 */
export const GetVehicleHistoryPDFV2TypeEnum = {
    Pexhistoricototalplus: 'PEXHISTORICOTOTALPLUS',
    Pexbasica: 'PEXBASICA'
} as const;
export type GetVehicleHistoryPDFV2TypeEnum = typeof GetVehicleHistoryPDFV2TypeEnum[keyof typeof GetVehicleHistoryPDFV2TypeEnum];
