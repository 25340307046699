import { GetReportDto, GetVehicleDto } from '../../../clients';

export const buildObject = (params: URLSearchParams) => {
	const vehicle: Partial<GetVehicleDto> = {};
	const reports: Partial<GetReportDto> = {};

	const vehicleMappings: { [key in keyof GetVehicleDto]?: (value: string | null) => any } = {
		brandModel: (value) => value ?? undefined,
		chassis: (value) => value ?? undefined,
		cityRegistration: (value) => value ?? undefined,
		color: (value) => value ?? undefined,
		cylinderCapacity: (value) => value ?? undefined,
		document_number: (value) => value ?? undefined,
		fuel: (value) => value ?? undefined,
		licensePlate: (value) => value ?? undefined,
		manufactureYear: (value) => value ?? undefined,
		modelYear: (value) => value ?? undefined,
		potency: (value) => value ?? undefined,
		renavam: (value) => value ?? undefined,
		seats: (value) => value ?? undefined,
		specie: (value) => value ?? undefined,
		type: (value) => value ?? undefined,
		stateRegistration: (value) => value ?? undefined,
		category: (value) => value ?? undefined,
		issueDate: (value) => (value ? new Date(value) : undefined),
		owner: (value) => {
			if (params.get('odoc') || params.get('oname')) {
				return {
					document: params.get('odoc') ?? undefined,
					name: params.get('oname') ?? undefined,
					documentType: '',
					licensePlate: params.get('lp') ?? undefined,
				};
			}
			return undefined;
		},
	};

	const reportMappings: { [key in keyof GetReportDto]?: (value: string | null) => any } = {
		reprocessing_date: (value) => (value ? new Date(value) : undefined),
		created_at: (value) => (value ? new Date(value) : undefined),
		transaction_number: (value) => value ?? undefined,
		document_type: (value) => value ?? undefined,
		consult_value: (value) => value ?? undefined,
		license_plate: () => vehicle?.licensePlate ?? undefined,
		seller: (value) => {
			if (params.get('sname') || params.get('scpf') || params.get('scnpj')) {
				return {
					cnpj: params.get('scnpj') ?? undefined,
					cpf: params.get('scpf') ?? undefined,
					name: params.get('sname') ?? undefined,
					cns: params.get('scns') ?? undefined,
					address_street: params.get('address_street') ?? undefined,
					address_number: params.get('address_number') ?? undefined,
					address_district: params.get('address_district') ?? undefined,
					address_city: params.get('address_city') ?? undefined,
					address_state: params.get('address_state') ?? undefined,
				};
			}
			return undefined;
		},
	};

	for (const key of Object.keys(vehicleMappings) as Array<keyof GetVehicleDto>) {
		const value = params.get(key);
		const result = vehicleMappings[key]?.(value);
		if (result !== undefined) {
			if (key === 'owner') {
				const owner = result as GetVehicleDto['owner'];
				if (owner && (owner.document || owner.name)) {
					vehicle.owner = owner;
				}
			} else {
				vehicle[key] = result;
			}
		}
	}

	for (const key of Object.keys(reportMappings) as Array<keyof GetReportDto>) {
		const value = params.get(key);
		const result = reportMappings[key]?.(value);
		if (result !== undefined) {
			reports[key] = result;
		}
	}

	return { vehicle: vehicle as GetVehicleDto, reports: reports as GetReportDto };
};
