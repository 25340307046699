import * as React from 'react';

import { useToasts } from 'react-toast-notifications';
import { usePubNub } from 'pubnub-react';

import { GetReportDto, GetVehicleByLicensePlateDocumentTypeEnum, GetVehicleDto, GetVehicleInquiriesDto, VehiclesApi } from '../clients';

import { getApiAuthConfig } from '../services/api.service';

import { useAuth } from './AuthProvider';
import { RealTimeChannel, RealTimeEvent } from '../services/real-time.service';

interface FetchContextData {
	fetchData: (
		licensePlate: string,
		crvAtpve: GetVehicleByLicensePlateDocumentTypeEnum,
		whatsapp: string,
		navigate: (path: string) => void,
		email?: string
	) => void;
	licensePlateData: GetVehicleDto | undefined;
	isLoading: boolean;
	report: GetReportDto | undefined;
	setReport: React.Dispatch<React.SetStateAction<GetReportDto | any>>;
	getInquiries: () => void;
	inquiries: GetVehicleInquiriesDto | undefined;
	inquiriesLoading: boolean;
	atpveUrl: string | undefined;
	atpveUrlLoading: boolean;
	isAtpveSuccess: boolean;
	setAtpveUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FetchDataContext = React.createContext<FetchContextData>({} as FetchContextData);

type FetchDataProviderProps = {
	children: React.ReactElement;
};

export const FetchDataProvider: React.FC<FetchDataProviderProps> = ({ children }) => {
	const { addToast } = useToasts();
	const { user } = useAuth();
	const pubNub = usePubNub();
	const [licensePlateData, setLicensePlateData] = React.useState<GetVehicleDto | undefined>(undefined);
	const [report, setReport] = React.useState<GetReportDto | any>(undefined);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [inquiries, setInquiries] = React.useState<GetVehicleInquiriesDto | undefined>(undefined);
	const [inquiriesLoading, setInquiriesLoading] = React.useState<boolean>(false);
	const [atpveUrl, setAtpveUrl] = React.useState<string | undefined>(undefined);
	const [isAtpveSuccess, setIsAtpveSuccess] = React.useState<boolean>(false);
	const [atpveUrlLoading, setAtpveUrlLoading] = React.useState<boolean>(true);
	const apiConfig = getApiAuthConfig();
	const vehiclesApi = new VehiclesApi(apiConfig);

	React.useEffect(() => {
		if (report) {
			const channels = pubNub.getSubscribedChannels()
				.filter((channel) => channel !== RealTimeChannel.MAINTENANCE_MODE);
			pubNub.unsubscribe({ channels });

			pubNub.addListener({
				message(event) {
					const { message } = event;
					const { inquiries, atpve_document } = message;
	
					if (inquiries && inquiries.eventType === RealTimeEvent.INQUIRIES) {
						setInquiries(inquiries.inquiries);
						setInquiriesLoading(false)
					}
					if (atpve_document && atpve_document.eventType === RealTimeEvent.ATPVE_DOCUMENT) {
						const { atpve_document } = message;
						setAtpveUrl(atpve_document.documentUrl);
						setAtpveUrlLoading(false)
						setIsAtpveSuccess(atpve_document.documentUrl.length)
					}
				},
			});
		}
		pubNub.subscribe({channels: [ report?.id ]})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	async function fetchData(
		licensePlate: string,
		crvAtpve: GetVehicleByLicensePlateDocumentTypeEnum,
		whatsapp: string,
		navigate: (path: string) => void,
		email?: string
	) {
		setIsLoading(true);
		setIsAtpveSuccess(true);
		setAtpveUrlLoading(true);

		try {
			const licensePlateResponse = await vehiclesApi.getVehicleByLicensePlate({ licensePlate, documentType: crvAtpve, whatsapp, email });
			const { vehicle, report, consult_debt } = licensePlateResponse;

			setLicensePlateData(vehicle);
			setReport({ ...report, url: consult_debt?.url, inquiries: vehicle.inquiries });

			if (crvAtpve === GetVehicleByLicensePlateDocumentTypeEnum.Crv) {
				navigate('/admin/resultado-crv');
			}

			if (crvAtpve === GetVehicleByLicensePlateDocumentTypeEnum.Atpve) {
				try {
					navigate('/admin/resultado-atpv');
					await getAtpveDocument(vehicle, report);
				} catch (buyerError) {
					addToast(`Ocorreu um erro ao tentar buscar os dados do comprador.`, {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			}

			const body = {
				vehicle,
				report,
				seller: user.seller,
			};

			await vehiclesApi.downloadPayerReceipt({ downloadPayerReceiptDto: body });
		} catch (vehicleError: any) {
			if (vehicleError?.response?.status === 404) {
				addToast('Placa não encontrada, favor conferir os dados para repetir a consulta', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}

			addToast(`Ocorreu um erro ao tentar buscar os dados do veículo.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	async function getAtpveDocument(vehicle: GetVehicleDto, report: GetReportDto) {
		try {
			if (vehicle.stateRegistration === 'MG') {
				await vehiclesApi.getAtpveDocument({ reportId: report.id });
			}
		} catch {}
	}

	async function getInquiries() {
		setInquiriesLoading(true);
		try {
			if (report && licensePlateData?.stateRegistration === 'MG') {
				const { id, license_plate, cns, transaction_number, document_type } = report;
				vehiclesApi.getInquiries({
					reportId: id,
					licensePlate: license_plate,
					cns,
					reference: transaction_number,
					type: document_type,
				});
			}
		} catch {}
	}

	return (
		<FetchDataContext.Provider
			value={{
				fetchData,
				licensePlateData,
				isLoading,
				report,
				setReport,
				getInquiries,
				inquiries,
				inquiriesLoading,
				atpveUrl,
				setAtpveUrl,
				isAtpveSuccess,
				atpveUrlLoading
			}}
		>
			{children}
		</FetchDataContext.Provider>
	);
};

export function useFetchData() {
	const context = React.useContext(FetchDataContext);

	return context;
}
